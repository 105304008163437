.vehicleDetailsContainer {
  min-width: 200px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.detailsTitle {
  font-size: 21px;
}
.frontRearPsiContainer {
  display: flex;
  gap: 8px;
}
.inputsContainer {
  width: 320px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.psiTorqueButton {
  display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 12px 24px;
	gap: 10px;
	background: #d70000;
	border: none;
	border-radius: 100px;
	color: #ffffff;
	min-width: 120px;
}
.psiTorqueButton:hover {
	background: #a30000;
	cursor: pointer;
}

.psiTorqueButton:disabled {
	border: 1px solid #c2c2c2;
	background: #efefef;
	color: #767676;
  pointer-events: none;
}

.psiTorqueButtonText {
	height: 21px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 150%;
	display: flex;
	justify-content: center;
	flex: none;
	order: 0;
	flex-grow: 0;
	color: black;
}
.psiTorqueContainer {
  display: flex;
  gap: 8px
}
.vehicleDetailsButtonsContainer {
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}