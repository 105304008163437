.orderSummaryContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 320px;
}

.orderSummaryLabel {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 135%;
  display: flex;
  align-items: center;
  color: #141414;
}

.breakdownsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 23rem;
  max-height: calc(100% - 7rem);
  overflow-y: scroll;
}

.breakdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #eeeeee;
  border-radius: 8px;
  padding: 1rem;
  width: 100%;
}

.breakdownContent {
  width: 100%;
}

.breakdownTitle {
  font-weight: 700;
  font-family: 'Poppins';
}

.tireSummaryContainer {
  width: 100%;
}

.orderServiceRow {
  border-top: solid 2px #c2c2c2;
  display: grid;
  grid-template-columns: 10rem min-content 1fr;
  padding-top: .75rem;
  margin-top: .75rem;
  width: 100%;
}

.orderServiceRow > :last-child {
  justify-self: end;
}

.estimatedTotalContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: 320px;
  font-family: 'Poppins';
  font-style: normal;
  font-size: 17.5px;
  line-height: 150%;
  color: #141414;
}

.estimatedTotalLabel {
  font-weight: 600;
  display: flex;
  align-items: center;
}

.estimatedTotalPrice {
  font-weight: 400;
  display: flex;
  align-items: center;
}
