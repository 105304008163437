.csToolsModal {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 40px;
	isolation: isolate;
	position: fixed;
	max-width: 90%;
	height: 90%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #ffffff;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
	border-radius: 12px;
}
.content {
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
}

.pageTitle {
	font-weight: 700;
	font-size: 24px;
	margin-bottom: 25px;
}
.searchBar {
	margin-bottom: 24px;
	margin-top: 5px;
	display: flex;
	gap: 16px;
}
.vehicleInfo {
	display: flex;
	margin-bottom: 28px;
}
.rowTitle {
	font-weight: 700;
	font-size: 21px;
}
.vehicleRow {
	min-width: 300px;
}
.tablesContainer {
	display: flex;
	/* width: 1000px; */
	margin-top: 16px;
}
.tableContainer {
	overflow-x: scroll;
	scrollbar-width: auto;
	-ms-overflow-style: auto;
}
.invoiceTable {
	width: fit-content;
}
.tableContainer::-webkit-scrollbar {
	display: block;
}
.tableContainer::-webkit-scrollbar {
	width: 10px;
}

.tableContainer::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 4px;
}

.tableContainer::-webkit-scrollbar-thumb:hover {
	background: #555;
	border-radius: 4px;
}
