.promoHeaderContainer {
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  flex-grow: 1;
}
.promoHeaderContainer:first-child {
  margin-left: 1rem;
}
