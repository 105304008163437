.trSalesModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  gap: 16px;
  isolation: isolate;
  position: absolute;
  width: 40%;
  min-width: 500px;
  height: 550px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}
.information {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.customerInformation {
  margin-top: 15px;
}
.confirmationModalHeader {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 0px;
  gap: 10px;
}
.confirmationModalHeaderButtons {
  display: flex;
  flex-direction: row;
  gap: 2em;
  padding-top: 1em;
  justify-content: space-between;
  align-items: center;
}
.confirmationModalPageTitle {
  font-size: 28px;
}
