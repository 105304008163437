:root {
  --border-color: #555555;
  --grey-background-color: #d9d9d9;
  --white-background-color: #fff;
}

/* Ensure the table container allows vertical and horizontal scrolling */
.tableWrapperContainer {
  max-height: 75vh;
  overflow: auto;
  border: 1px solid var(--border-color);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-radius: 8px;
  width: 100%;
  margin-top: 1rem;
  font-size: 1rem;
}

/* Basic table styling */
.futurePercentBookedTable {
  width: 100%;
  border-spacing: 0;
  table-layout: fixed;
}

.headerCell {
  padding: 16px 8px;
  border-bottom: 1px solid var(--border-color);
  background-color: #b7b7b7;
  position: sticky;
  top: 0;
  width: 5.5rem;
  white-space: nowrap;
}
.headerCell:nth-child(1),
.headerCell:nth-child(2) {
  z-index: 2000;
}
.headerCell:nth-child(n + 3) {
  z-index: 1000;
}

.futurePercentBookedTable td,
.futurePercentBookedTable th {
  padding: 12px 4px;
  text-align: left;
  border: 1px solid var(--border-color);
}

.futurePercentBookedTable td {
  padding: 12px 8px;
}

/* Alternate between grey and white every 3 rows */
.futurePercentBookedTable tr:nth-child(6n + 1) td:nth-child(1),
.futurePercentBookedTable tr:nth-child(6n + 2) td:nth-child(1),
.futurePercentBookedTable tr:nth-child(6n + 3) td:nth-child(1),
.futurePercentBookedTable tr:nth-child(6n + 1) td:nth-child(2),
.futurePercentBookedTable tr:nth-child(6n + 2) td:nth-child(2),
.futurePercentBookedTable tr:nth-child(6n + 3) td:nth-child(2) {
  background-color: var(--grey-background-color);
}
.futurePercentBookedTable tr:nth-child(6n + 1),
.futurePercentBookedTable tr:nth-child(6n + 2),
.futurePercentBookedTable tr:nth-child(6n + 3) {
  background-color: var(--grey-background-color);
}

.futurePercentBookedTable tr:nth-child(6n + 4) td:nth-child(1),
.futurePercentBookedTable tr:nth-child(6n + 5) td:nth-child(1),
.futurePercentBookedTable tr:nth-child(6n + 6) td:nth-child(1),
.futurePercentBookedTable tr:nth-child(6n + 4) td:nth-child(2),
.futurePercentBookedTable tr:nth-child(6n + 5) td:nth-child(2),
.futurePercentBookedTable tr:nth-child(6n + 6) td:nth-child(2) {
  background-color: var(--white-background-color);
}
.futurePercentBookedTable tr:nth-child(6n + 4),
.futurePercentBookedTable tr:nth-child(6n + 5),
.futurePercentBookedTable tr:nth-child(6n + 6) {
  background-color: var(--white-background-color);
}

/* Remove left border on all but 1st cell for overlapping inside borders */
.futurePercentBookedTable td:not(:first-child),
.futurePercentBookedTable th:not(:first-child) {
  border-left: none;
}

/* Remove top border on all but 1st row for overlapping inside borders */
.futurePercentBookedTable tr:not(:first-child) td,
.futurePercentBookedTable tr:not(:first-child) th {
  border-top: none;
}

/* Remove overlapping outside borders for the first and last cells of each row */
.futurePercentBookedTable tr td:first-child,
.futurePercentBookedTable tr th:first-child {
  border-left: none;
}

.futurePercentBookedTable tr td:last-child,
.futurePercentBookedTable tr th:last-child {
  border-right: none;
}

/* for cells in the first and last row */
.futurePercentBookedTable tr:first-child td,
.futurePercentBookedTable tr:first-child th {
  border-top: none;
}

.futurePercentBookedTable tr:last-child td {
  border-bottom: none;
}

/* Ensure first header column is sticky */
.futurePercentBookedTable th:nth-child(1),
.futurePercentBookedTable td:nth-child(1) {
  position: sticky;
  left: 0;

  width: 10rem;
}
.futurePercentBookedTable td:nth-child(1) {
  z-index: 999;
}

/* Ensure second header column is sticky */
.futurePercentBookedTable th:nth-child(2),
.futurePercentBookedTable td:nth-child(2) {
  position: sticky;
  left: 10rem;
  width: 8rem;
}
.futurePercentBookedTable td:nth-child(2) {
  z-index: 999;
}

/* Subsequent columns scroll under the first two */
.futurePercentBookedTable td:nth-child(n + 3) {
  position: relative;
  z-index: 100;
}

/* Styling for special cells */
.totalCell {
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  border-top: none;
}

.highlightedCell {
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  border-bottom: none;
}

.highlightedCellWithToolTip {
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  border-bottom: none;
  cursor: help;
}

.highlightedCellWithToolTip::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translateX(0%);
  width: 400px; /* Set fixed width */
  background-color: rgba(0, 0, 0, 0.75);
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 1rem;
  opacity: 0;
  visibility: hidden;
  white-space: normal;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 10000;
  text-align: left;
  box-sizing: border-box;
}

.highlightedCellWithToolTip:hover::after {
  opacity: 1;
  visibility: visible;
}

.noVerticalBorders {
  border-top: none;
  border-bottom: none;
  background-color: #f8f8f8;
}

.weeklyTotalCell,
.highlightedCell,
.totalCell {
  font-weight: bold;
}

.totalCell {
  background-color: #f8f8f8;
}

/* Remove left border for first cell in rows */
.futurePercentBookedTable tr td:first-child,
.futurePercentBookedTable tr th:first-child {
  border-left: none;
}
