.appointmentBookingContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.progressContainer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.infoSearchBarContainer {
  display: flex;
  gap: 4rem;
}
.schedulerWrapperContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.confirmationText {
  max-width: 475px;
}
