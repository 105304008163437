.true {
	width: 24px;
	height: 24px;
	border-radius: 100%;
	background-color: #06893c;
}
.false {
	width: 24px;
	height: 24px;
	border-radius: 100%;
	border: thin solid #767676;
}
