.userErrorScreen1 {
  margin: 20px auto;
  text-align: center;
  font-size: 30px;
  margin-left: 450px;
  background-color: #f5c6cb;
  border: 1px solid #721c24;
  border-radius: 15px;
  padding: 10px;
  display: block;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.userErrorScreen {
  font-size: 24px;
  text-align: center;
  margin-left: 450px;
  color: #000000;
  line-height: 1;
}
