.scheduleModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  isolation: isolate;
  position: fixed;
  padding: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  max-width: 560px;
}
.scheduleModalPageTitle {
  font-size: 28px;
  margin-bottom: 16px;
}
.inputsContainer {
  display: flex;
  flex-direction: column;
}
.checkBoxContainer {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 0px;
  gap: 8px;
  margin-top: 16px;
}

.radialValuesContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
}

.deleteButtonVisibleFooterContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.deleteButtonInvisibleFooterContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  padding: 0px;
  gap: 8px;
  margin-top: 16px;
}

.topForceMoveContainer {
  width: 400px;
  white-space: normal;
  word-wrap: break-word;
}

.topInputsContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  width: 100%;
}

.topInputsContainer > div {
  width: 100%;
}

.addDiscountsContainer {
  display: flex;
  flex-direction: column;
  width: 570px;
}

.braintreeText {
  margin-bottom: 24px;
  text-align: center;
}

.editDiscountsContainer {
  display: flex;
  flex-direction: column;
  width: 400px;
}

.radialButtonsContainer {
  margin-bottom: 8px;
}

.headerTwo {
  font-weight: 700;
  font-size: 17.5px;
  margin-bottom: 16px;
}

.warningText {
  width: 100%;
  color: #d70000;
  text-align: center;
}

.willBeFreeMessage {
  margin-top: 20px;
  text-align: center;
}

/* styles below are specific to line item / cases modal */

.topInputsContainerLineItem {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  width: 100%;
}
.topInputsContainerLineItem > div {
  margin-bottom: 16px;
}
.notesTitle {
  font-weight: 700;
  font-size: 17.5px;
}
.notesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 90%;
  border: thin solid #c2c2c2;
  border-radius: 5px;
  padding: 10px;
}
.notesBox {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.note {
  margin-bottom: 8px;
}
.addNoteButton {
  cursor: pointer;
}
.noTechs {
  color: #d70000;
}
.centerText {
  text-align: center;
}
.progressContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* Installation Images Modal */

.installImagesMainContainer {
  display: flex;
  flex-direction: column;
  max-height: 800px;
}
.imageLinks {
  all: unset;
  color: #d70000;
  cursor: pointer;
}
.imagesFlexContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 24px;
  overflow-y: auto;
  max-height: 600px;
}
.imageTitle {
  font-weight: 600;
  font-size: 16px;
}
.installImage {
  width: 300px;
  height: 533px;
  object-fit: cover;
}

.statusWarning {
  margin-bottom: 16px;
  width: 100%;
}
