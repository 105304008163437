.locationFormContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 8px;
  border: 1px solid #c2c2c2;
  border-radius: 8px;
}

.locationFormFlexBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.locationFormTitle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 135%;
  display: flex;
  align-items: center;
}

.doubleFieldContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
}

.workdayLabel {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #141414;
}

.checkboxContainer {
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 0px;
  width: 308px;
}

.wideTextField {
  width: 320px;
}

.narrowTextField {
  width: 156px;
}

.locationCheckboxSmallText {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
}
