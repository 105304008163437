.scheduleModal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  gap: 32px;
  isolation: isolate;
  position: absolute;
  max-width: 1120px;
  width: calc(100% - 10rem);
  /* height: 688px; */
  height: 700px;
  left: 50%;
  transform: translateX(-50%);
  top: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}
.scheduleModalPageTitle {
  font-size: 28px;
  width: 100%;
  height: 42px;
  display: flex;
  gap: 40px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px;
  color: #d70000;
}

.bottomNavBar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding: 0px;
}

.chargePaymentMethodWarningContainerText {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 11.5px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #141414;
  padding-right: 10px;
  padding-bottom: 10px;
}

.pagesLinks {
  display: flex;
  gap: 8px;
}
.buttonDisabled {
  pointer-events: none;
  color: #767676;
}

.pagesLinks button:not(:last-child) {
  border-right: thin solid #c2c2c2;
  padding-right: 8px;
}
.flexButtons {
  display: flex;
  gap: 8px;
}
.flexGroupButtons {
  display: flex;
  gap: 8px;
}
.navLink {
  cursor: pointer;
  all: unset;
}
.navLink:hover {
  text-decoration: underline;
  cursor: pointer;
}
.navLink:visited {
  color: #d70000;
}
.active {
  color: black;
  font-weight: 700;
}
.active {
  color: black;
  font-weight: 700;
}
