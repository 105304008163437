.techNameAddress {
	margin-left: 8px;
}
.time {
	color: #767676;
	display: grid;
	grid-template-columns: auto auto;
}
.copyRoute:hover {
	cursor: pointer;
}
.name {
	font-weight: 600;
	display: grid;
	grid-template-columns: auto auto;
	align-items: center;
	width: 100%;
}
.techInfoContainer {
	margin: 8px;
}
.container {
	display: grid;
	grid-template-columns: min-content auto;
	padding: 8px;
	border-radius: 8px;
	width: 224px;
	cursor: pointer;
	gap: 8px;
}

.container.blank {
	padding-bottom: 0;
}

.container.blank:hover {
	cursor: auto;
	background-color: transparent;
}

.unassigned {
	background-color: #d70000;
	color: #ffffff;
	cursor: auto;
}
.assigned:hover {
	background-color: #ffe785;
}
.techAvatar {
	height: 40px;
	width: 40px;
	border-radius: 100px;
}
.containerTwo {
	display: flex;
	padding-left: 4rem;
}

.copyRoute {
	color: #d70000;
	margin-top: 16px;
}
/*  */

.technicianAvailability {
	width: 12px;
	height: 12px;
	border-radius: 50%;
	border: 2px solid #767676;
}

.technicianAvailability.working {
	background: #06893c;
	border: 2px solid #06893c;
}

.daySetting {
	border: 2px solid #767676;
	background: linear-gradient(to right, #767676 50%, transparent 50%);
}

.daySetting.working {
	border: 2px solid #06893c;
	background: linear-gradient(to right, #06893c 50%, transparent 50%);
}

.blankNotification {
	padding: .25rem .5rem;
	background-color: #d70000;
	border-radius: .25rem;
	text-align: center;
	color: #ffffff;
	font-weight: bold;
	font-size: 11px;
	justify-self: end;
}