.techModal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  gap: 16px;
  isolation: isolate;
  position: absolute;
  width: 1500px;
  height: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}

.locationModalHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 0px;
  gap: 10px;
}

.locationModalHeaderButtons {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.locationModalPageTitle {
  font-size: 28px;
}

.mainContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 18px;
  overflow-y: scroll;
  width: 100%;
}

.tableContainer {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  flex-grow: 2;
}

.progressContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
