.mainContainer {
  display: flex;
  flex-direction: column;
}
.avatarContainer {
  border-radius: 8px;
  margin-bottom: 16px;
}
.imageContainer {
  width: 150px;
  height: 150px;
  overflow: hidden;
  border: thin solid #c2c2c2;
  border-radius: 8px;
  margin-bottom: 8px;
}
.updateImageBtn {
  all: unset;
  color: #d70000;
  cursor: pointer;
  margin-bottom: 28px;
}
.updateImageBtn:hover {
  text-decoration: underline;
}
.inputsContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.hours {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.hoursHeading {
  font-size: 14px;
  font-weight: 700;
}
.hoursInputs {
  display: flex;
  gap: 8px;
}
.useLocationDefault {
  display: flex;
  justify-content: left;
  align-items: center;
}
.uploadButton {
  cursor: pointer;
  color: #d70000;
}
.uploadButton:hover {
  text-decoration: underline;
}
.technicianModalError {
  color: #d70000;
}
