.appointmentSlotsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  background-color: white;
}
.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  width: 1040px;
  height: 457px;
}

.notSelectable {
  pointer-events: none;
}

.monthsHeader {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 8px;
  gap: 10px;
  width: 100%;
  height: 23px;
  background: #141414;
}

.monthsHeaderText {
  width: 103px;
  height: 15px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #ffffff;
}

.slotsContainer {
  width: 100%;
  height: fit-content;
  overflow: auto;
}
.calContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 16px;
  position: absolute;
  width: 1376px;
  height: 433px;
  left: -61px;
  top: 1px;
}

.daysContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 1376px;
  height: fit-content;
}

.day {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 0px 16px;
  gap: 4px;
  width: 140px;
}

.dayHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 140px;
  height: 73px;
}

.dayHeaderMonthText {
  height: 36px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #141414;
}

.dayHeaderDayTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  height: 21px;
}

.dayHeaderDayTextWeekday {
  height: 21px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #141414;
}

.slotContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 140px;
  /* height: 308px; */
  justify-content: center;
}

.slotCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 16px;
  width: 140px;
  height: 71px;
  cursor: pointer;
  border: 1px solid #c2c2c2;
  border-radius: 8px;
}

.slotCardWindow {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #141414;
  text-transform: capitalize;
}

.slotCardTimeRange {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #141414;
}

.line {
  width: 409px;
  height: 0px;
  border: 1px solid #c2c2c2;
  transform: rotate(90deg);
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.overlay .progressContainer {
  text-align: center;
}

@media (max-width: 1023px) {
  .appointmentSlotsContainer {
    width: 100%;
  }
  .loadingContainer {
    width: 100%;
  }
  .monthsHeader {
    width: 100%;
  }
  .slotsContainer {
    width: 100%;
  }
}
@media (max-width: 650px) {
  .appointmentSlotsContainer {
    height: 100%;
  }
  .dayHeader {
    width: 100%;
  }
  .daysContainer {
    flex-direction: column;
    width: 100%;
  }
  .day {
    width: 100%;
    height: 100%;
  }
  .slotContainer {
    flex-direction: row;
    width: 300px;
    height: 100%;
    flex-wrap: wrap;
  }
}

.prevNextButton {
  all: unset;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 25px;
  height: 229px;
  border: 1px solid #c2c2c2;
  border-radius: 8px;
}
.prevNextButton:hover {
  background-color: #eeeeee;
  cursor: pointer;
}
.prevNextButton:disabled {
  background-color: #eeeeee;
  cursor: unset;
}
.prevNextContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 85px;
}
