.newVanModal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  isolation: isolate;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}
.newVanModalPageTitle {
  font-size: 28px;
  margin-bottom: 32px;
}
.contentContainer {
  display: flex;
  flex-direction: column;
}
.formContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.top {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.topRight {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 300px;
}
.topLeft {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 240px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  padding: 0px;
  gap: 8px;
  margin-top: 24px;
}
.progressContainer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.qrCodeContainer {
  width: 100%;
}

.qrCodeContainer > img {
  width: 300px;
  height: 300px;
  object-fit: contain;
}
