.servicedZipsModal {
  display: flex;
  flex-direction: column;
  padding: 40px;
  isolation: isolate;
  position: absolute;
  max-width: 1600px;
  width: calc(100% - 5rem);
  max-height: 900px;
  left: 50%;
  transform: translateX(-50%);
  top: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}
.inputContainer {
  display: flex;
  gap: 1rem;
  width: 100%;
  justify-content: space-between;
}
.servicedZipsHeader {
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
}
