.mainContentContainer {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow-y: scroll;
  max-height: 100%;
  max-width: 100%;
}
.sectionContainer {
  padding: 2em 0;
}
