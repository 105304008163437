.userModal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  gap: 16px;
  isolation: isolate;
  position: absolute;
  min-width: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}
.userModalTitle {
  font-size: 28px;
}
.footerButtons {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.contentContainer {
  min-height: 15rem;
  width: 100%;
}
.formContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.checkBoxContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
