.navContainer {
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  margin-left: 16px;
}
.textContainer {
  display: flex;
  align-items: center;
}
.showKey {
  color: #d70000;
}
.showKey:hover {
  cursor: pointer;
}
