.cardContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px;
  gap: 0px;
  width: 280px;
  height: 170px;
  border-radius: 8px;
  font-weight: 400;
  flex-shrink: 0;
  margin: 1rem 8px;
}

.cardContainer:hover {
  cursor: pointer;
}

.cardBottomContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 94px;
  justify-content: flex-start;
}

.cardTopContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
}

.customerName {
  font-size: 14px;
}

.orderNumbers,
.shippingMethod,
.shippingStatus {
  font-weight: 700;
}

.shippingInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.statusOfOrder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  gap: 10px;
  border-radius: 100px;
}

.statusText {
  font-weight: 600;
  font-size: 10px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  flex: none;
  order: 0;
  flex-grow: 0;
}
