.flexConfirmationContainer {
	display: flex;
	width: 100%;
	background-color: white;
	padding: 2rem;
	flex-direction: column;
	gap: 1rem;
}

.appointmentContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 24px;
	width: 100%;
}

.appointmentTimeContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 8px;
	width: 100%;
}

.appointmentTimeContainerTitle {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 21px;
	line-height: 135%;
	display: flex;
	align-items: center;
	color: #141414;
}

.confirmAppointmentSmallTextContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 2px;
}

.confirmAppointmentSmallText {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 150%;
	display: flex;
	align-items: center;
	color: #141414;
}
.orderInfo {
	display: flex;
}
.message {
	font-size: 16px;
	font-weight: 500;
}
@media (max-width: 767px) {
	.flexConfirmationContainer {
		flex-direction: column;
	}
}
@media (max-width: 660px) {
	.appointmentContainer {
		align-items: center;
		justify-content: center;
	}
	.orderInfo {
		flex-direction: column;
		gap: 8px;
	}
}
@media (max-width: 350px) {
	.flexConfirmationContainer {
		padding: 0;
	}
}
