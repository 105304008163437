/* ChangeOperatingStatusModal.tsx && ConfirmVanChangesModal.tsx */
.dropDown {
  width: 160px;
}
.changeOperatingStatusMessage {
  margin-top: 16px;
  width: 300px;
  font-size: 12px;
  font-style: italic;
}

.inventoryStatus {
  width: 170px;
}
.outOfServiceDateMessage {
  margin-top: 16px;
  width: 300px;
  font-size: 14px;
}
.defaultSaveConfirmationMessage {
  margin-top: 16px;
  margin-bottom: 16px;
}
.confirmCheckbox {
  margin-top: 16px;
}
.editVanSubModalButtons {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
}
.editVanSubModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  isolation: isolate;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}
.editVanSubModalPageTitle {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 16px;
}
.editVanSubModalContent {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: center;
}
.requiredFieldContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}

/* EditVanFormComponent.tsx */
.formContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.operatingStatusInfo {
  font-size: 1rem;
  font-weight: bold;
}
.operatingStatusContainer {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;

  margin-bottom: 16px;
  padding: none;
}
.top {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.topRight {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 300px;
}
.topLeft {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 240px;
}
.bottom {
  margin-top: 8px;

  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.bottomLeft {
  width: 240px;
}
.bottomRight {
  width: 300px;
}
.error {
  margin: 0;
}
.qrCodeTitle {
  font-size: 1rem;
  font-weight: bold;
}
.qrCodeContainer {
  width: 100%;
}

.qrCodeContainer > img {
  width: 200px;
  height: 200px;
  object-fit: contain;
}
