.modalWrapper {
  height: 92vh;
  width: 92vw;
}
.bodyRowCell {
  padding: 1rem;
  border-top: thin solid #c2c2c2;
  vertical-align: middle;
  max-width: 11rem;
  word-wrap: break-word;
}

.bodyRow {
  border-top: thin solid black;
  transition: background-color 0.1s;
}

.textfieldBackground {
  color: #ffffff;
}

.tableTopperContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.titleButtonContainer {
  display: flex;
  flex-direction: row;
}

.buttonCell {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
}

.buttonTextBox {
  width: 60px;
  text-align: center;
}

.buttonText {
  all: unset;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #d70000;
  cursor: pointer;
}
.buttonText:hover {
  text-decoration: underline;
}

.line {
  width: 24px;
  height: 0px;
  border: 1px solid #c2c2c2;
  transform: rotate(90deg);
}
.uploadButton {
  cursor: pointer;
  color: #d70000;
}
.uploadButton:hover {
  text-decoration: underline;
}
