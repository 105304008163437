.recordsModal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;
  isolation: isolate;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 500px;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}
