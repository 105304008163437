.formContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
	gap: 40px;
  min-height: 450px;
  max-height: 600px;
  overflow: auto;
}
.responsiveFormContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  background: white;
  padding: 1rem;
  gap: 12px;
}
.topInputsContainer {
	display: flex;
	flex-direction: row;
  justify-content: center;
	margin-bottom: 16px;
	width: 100%;
}
.tireQuantityContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 320px;
}
.errorContainer {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.errorMessage {
  color: red;
  font-size: 11px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.psiTorqueContainer {
  display: flex;
  gap: 8px
}

.overlay .progressContainer {
  text-align: center;
}

@media (max-width: 1023px) {
  .responsiveFormContainer {
    flex-direction: column;
    align-items: center;
  }
}


/* styles below are specific to psiTorqueModel */
.psiTorqueModal {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 40px;
	isolation: isolate;
	position: fixed;
	padding: 50px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #ffffff;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
	border-radius: 12px;
}
.psiTorqueModalTitle {
	font-size: 28px;
	margin-bottom: 32px;
}
.buttonContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 0px;
	gap: 8px;
	margin-top: 16px;
}

