.confirmAppointmentContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1rem;
  gap: 32px;
  width: 100%;
  overflow: auto;
  background: white;
}

.appointmentContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 320px;
}

.appointmentTimeContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  flex: 1;
  width: 308px;
}

.appointmentTimeContainerTitle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 135%;
  display: flex;
  align-items: center;
  color: #141414;
}

.confirmAppointmentSmallTextContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 2px;
}

.confirmAppointmentSmallText {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
}
.gap {
  gap: 12px;

}

.checkboxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;
  width: 308px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.overlay .progressContainer {
  text-align: center;
}
@media (max-width: 1050px) {
  .confirmAppointmentContainer {
    width: 100%;
    gap: 0;
    justify-content: center;
    align-items: flex-start;
  }
  .appointmentContainer {
    height: 100%;
    width: 100%;
  }
  .appointmentTimeContainer {
    width: 100%;
  }
}
@media (max-width: 850px) {
  .confirmAppointmentContainer {
    flex-direction: column;
    justify-content: flex-start;
  }
}
@media (max-width: 450px) {
  .confirmAppointmentContainer {
    padding: 0 1rem;
  }
}
