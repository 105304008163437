.customTireSizeContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  width: 336px;
  border: 1px solid #c2c2c2;
  border-radius: 8px;
  gap: 4px;
}

.specifyCustomSizeTitle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  width: 100%;
  line-height: 150%;
  color: #141414;
}

.frontRearContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;
  width: auto;
  width: 100%;
}

.dropdownContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.dropdownContainerLabel {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  width: 40px;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #141414;
}
.selectContainer {
  display: flex;
  /* flex-direction: row; */
  flex-direction: column;
  gap: 8px;
}
@media (max-width: 1023px) {
  .customTireSizeContainer {
    height: 100%;
  }
  .frontRearContainer {
    flex-direction: row;
    justify-content: center;
  }
  .dropdownContainer {
    flex-direction: column;
    gap: 8px;
  }
  .selectContainer {
    flex-direction: column;
  }
}

@media (max-width: 700px) {
  .customTireSizeContainer {
    height: 100%;
    width: 336px;
    align-items: center;
  }
  .frontRearContainer {
    flex-direction: column;
    width: auto;
  }
  .dropdownContainer {
    gap: 4px;
  }
}
@media (max-width: 350px) {
  .customTireSizeContainer {
    width: 100%;
  }
}
