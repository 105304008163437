.subsectionTitle {
  font-size: 1rem;
  font-weight: bold;
}
.stateOfChargeContainer {
  all: unset;
  cursor: pointer;
  width: 100%;
}
.stateOfChargeContainer:hover {
  background-color: var(--color-warning);
}
.batteryDataModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  isolation: isolate;
  position: fixed;
  padding: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}
.tableContainer {
  display: flex;
  flex-direction: column;
  max-height: 800px;
  gap: 8px;
}
