.mainPageWrapperMain {
  height: 100%;
  max-height: 100vh;
  overflow-y: scroll;
  background-color: #fff;
  padding: 0rem 3rem 3rem 3rem;
  border-top-left-radius: 1.5rem;
  position: relative;
}
.mainPageWrapperTitle {
  font-size: 35px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}
.paddingDiv {
  height: 3rem;
}
.mainPageWrapperContainer {
  padding: 1rem 0;
}
.stickyHeader {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #fff;
}
