.link {
  text-decoration: none;
  color: unset;
  display: block;
}

.buttonsHolder {
  display: flex;
  gap: 1rem;
  margin: 1rem 0 2rem;
}
