.customerSchedulePage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  isolation: isolate;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #f5f5f5;
  overflow: auto;
}
.flowContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: thin solid #c2c2c2;
  background: white;
  border-radius: 8px;
  width: 1100px;
  padding: 2rem 0 0 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}
.errorContainer {
  margin: auto;
  display: flex;
  flex-direction: column;
  border: 1px solid #c2c2c2;
  border-radius: 12px;
  max-width: 650px;
  max-height: 710px;
  padding: 40px;
  gap: 16px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}
.errorHeader {
  font-size: 35px;
  font-weight: 700;
}
.logo {
  width: 142px;
  height: 28px;
}
.customerSchedulePageTitle {
  display: flex;
  font-size: 28px;
  align-items: center;
  width: 90%;
  gap: 16px;
  padding: 0 0 1rem 1rem;
}
.complianceAndButtonContainer {
	margin-top: 20px;
	width: 100%;
	display: flex;
	flex-direction: column;
}
.complianceLinks {
  margin-right: 20px;
  width: 300px;
	align-self: flex-end;
}
.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 2rem;
  padding-top: 0rem;
  gap: 16px;
}
.progressContainer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageContainer {
  max-height: 550px;
  width: 95%;
  overflow: auto;
}
.titleAndServiceDropDownContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
}

@media (max-width: 1125px) {
  .flowContainer {
    max-width: 99vw;
  }
  .customerSchedulePageTitle {
    text-align: center;
  }
}
@media (max-width: 660px) {
  .pageContainer {
    max-height: 100%;
    flex-grow: 2;
  }
  .flowContainer {
    box-shadow: none;
    border: none;
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .buttonContainer {
    padding: 0.5rem;
  }
  .customerSchedulePageTitle {
    font-size: 22px;
    padding: 1rem;
  }
  .complianceLinks {
    margin-right: 0px;
  }
}
@media (max-width: 450px) {
  .customerSchedulePageTitle {
    font-size: 18px;
    padding: 1rem;
    flex-direction: column;
    gap: 4px;
  }
  .errorContainer {
    height: 100%;
    gap: 8px;
    border: none;
    box-shadow: none;
  }
  .logo {
    width: 122px;
    height: 25px;
  }
  .complianceLinks {
    align-self: center;
  }
}
