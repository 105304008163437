.scheduleModal {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 40px;
	isolation: isolate;
	position: fixed;
	padding: 50px;
	width: 400px;
	top: 45%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #ffffff;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
	border-radius: 12px;
}

.scheduleModalPageTitle {
	font-size: 28px;
	margin-bottom: 24px;
}

.buttonContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: flex-start;
	width: 100%;
	padding: 0px;
	gap: 16px;
}
.errorMessage {
	color: red;
	margin-bottom: 8px;
}
.text {
	margin-bottom: 16px;
}
.progressContainer {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
