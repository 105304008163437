.containerWrapper {
	display: flex;
	gap: 32px;
	flex-wrap: wrap;
}
.container {
	min-width: 23rem;
	max-height: 22.5rem;
	border: thin solid #c2c2c2;
	border-radius: 8px;
}
.containerHeading {
	font-weight: 700;
	font-size: 21px;
	margin-left: 24px;
	margin-top: 24px;
	margin-bottom: 28px;
}
.link {
	margin-left: 24px;
	margin-right: 24px;
	margin-bottom: 16px;
	color: #d70000;
}
.navLink {
	cursor: pointer;
	all: unset;
}
.navLink:hover {
	text-decoration: underline;
	cursor: pointer;
}
.navLink:visited {
	color: #d70000;
}
