.tableContainer {
	width: 500px;
	padding: 16px;
	background-color: #eeeeee;
	border-radius: 8px;
}
.table {
	width: 100%;
	/* border-collapse: separate; */
	border-collapse: collapse;
	border-spacing: 0 16px;
}
.trBorderTop {
	border-top: thin solid #c2c2c2;
}
.table th,
.table td {
	text-align: start;
	padding: 5px;
}

.noBold {
	font-weight: 400;
}
.table > tr {
	margin-bottom: 50px;
}
