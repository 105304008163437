@import '../../css/colors.css';

.columnHeaderRow {
  padding: 0.5rem;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #fff;
}

.tableWrapper {
  max-height: 83vh;
  overflow-y: auto;
  border-bottom: thin solid #c2c2c2;
  border-right: thin solid #c2c2c2;
  border-left: thin solid #c2c2c2;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.table {
  border-spacing: 0;
  width: 100%;
}

.table th {
  height: 1px;
}

.tableHeader {
  padding-right: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  vertical-align: top;
}

.tableHeader.displayMode {
  background-color: #eeeeee;
  padding: 0.5rem 0.5rem;
}

.tableHeader:first-child {
  padding-left: 0.5rem;
}

.columnHeader {
  display: flex;
  align-items: center;
  user-select: none;
}

.columnHeader:hover {
  /* color: #333; */
  text-decoration: underline;
}

.columnHeaderTitle {
  text-align: left;
  margin: 0 0.5rem;
  padding: 0.25rem 0;
}

.bodyRowCell {
  padding: 1rem;
  /* padding: 0.5rem; */
  border-top: thin solid #c2c2c2;
  /* max-width: 11rem; */
  word-wrap: break-word;
}

.bodyRow {
  border-top: thin solid black;
  transition: background-color 0.1s;
}

.bodyRow:hover {
  background-color: var(--color-warning);
  cursor: pointer;
}

.bodyRowNoHover {
  pointer-events: none;
}

.filterContainer {
  display: grid;
  justify-items: left;
}

.filterSymbol {
  display: inline;
  position: relative;
}

.filterSymbol::after {
  background-image: url('../../../assets/images/filter.png');
  background-size: 0.7rem 0.6rem;
  vertical-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
  right: 0.5rem;
  display: inline-block;
  width: 0.7rem;
  height: 0.6rem;
  content: '';
}

.tableLoading {
  padding: 0.5rem 1rem 1rem 1rem;
  font-style: italic;
  overflow: visible;
  width: 10px;
  text-overflow: unset;
}

.tbodyOpen {
  display: block;
}

.tbodyClosed {
  display: hidden;
}

.table input {
  width: 100%;
}

.thFlexWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.highlightedRow {
  background-color: #eeeeee;
  font-weight: bold;
}

.highlightedRow2 {
  background-color: #fff3f3;
  font-weight: bold;
}
