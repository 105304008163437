.pageHeaderContainer {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.makeAppContainer {
  display: flex;
  flex-direction: column;
}
.inputsContainer {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 0.5rem;
}
.nextAvailableText {
  font-size: 12px;
  font-weight: 600;
  margin-left: 8px;
  color: #767676;
}
.availableText {
  color: black;
}
