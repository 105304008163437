.link {
  margin-top: 16px;
  cursor: pointer;
  color: #d70000;
}
.navLink {
  all: unset;
  color: #d70000;
}
.navLink:disabled {
  color: #c2c2c2;
  cursor: not-allowed;
}
.loadingCircle {
  margin-left: 8px;
}
.dashboardButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 1cm;
  padding: 1cm;
}
.link {
  text-decoration: none;
  color: unset;
  display: block;
}
.buttonsHolder {
  display: flex;
  gap: 1rem;
  margin: 1rem 0 2rem;
}
.tableContainer {
  display: flex;
  flex-direction: row;
}
