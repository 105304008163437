.adjustInvoiceModal {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 40px;
	isolation: isolate;
	position: fixed;
	padding: 50px;
	top: 45%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #ffffff;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
	border-radius: 12px;
	width: fit-content;
	max-width: 100%;
}

.scheduleModalPageTitle {
	font-size: 28px;
	margin-bottom: 24px;
}

.contentHolder {
	max-height: 55vh;
	overflow-y: scroll;
	border-bottom: thin solid #c2c2c2;
	box-shadow: 0px -1rem .5rem -1rem rgba(0, 0, 0, 0.15) inset;
	scrollbar-width: none;
}

.table {
	width: 500px;
	min-height: 200px;
}
.buttonContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: flex-start;
	width: 100%;
	padding: 2rem 0 0;
	gap: 16px;
}

.inputsContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	padding: 1rem 0;
}
.inputsContainer .flexIt:nth-child(2) {
	margin-left: 24px;
	margin-right: 24px;
}

.flexIt {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}
.flexIt:nth-child(1) div {
	margin-bottom: 8px;
}
.flexIt:nth-child(2) div {
	margin-bottom: 8px;
}

.headerTwo {
	font-weight: 700;
}
.inputButton {
	all: unset;
	cursor: pointer;
	color: #d70000;
}
.progressContainer {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.adjustmentTypeRadioButtonContainer {
	width: max-content;
}
