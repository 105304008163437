.bodyRowCell {
	padding: 1rem;
	border-top: thin solid #c2c2c2;
	vertical-align: middle;
	max-width: 11rem;
	word-wrap: break-word;
}

.bodyRow {
	border-top: thin solid black;
	transition: background-color 0.1s;
}

.textfieldBackground {
	color: #ffffff;
}

.titleButtonContainer {
	display: flex;
	flex-direction: row;
	gap: 8px;
	align-items: baseline;
}

.buttonCell {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;
}

.buttonText {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 150%;
	color: #d70000;
	cursor: pointer;
}

.line {
	width: 24px;
	height: 0px;
	border: 1px solid #c2c2c2;
	transform: rotate(90deg);
}
