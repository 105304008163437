.roleModal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  gap: 16px;
  isolation: isolate;
  position: absolute;
  min-width: 50vw;
  max-height: 98vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}
.roleModalTitle {
  font-size: 28px;
}
.footerButtons {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.contentContainer {
  min-height: 15rem;
  width: 100%;
  max-height: 75vh;
}
.optionsContainer {
  border: thin solid #c2c2c2;
  border-radius: 0.5rem;
  overflow-y: auto;
}
.formContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: inherit;
}

.formTextFields {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.checkBoxContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.categoryContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  max-height: fit-content;
}
.zeroMargin {
  margin: 0;
}

.permissionOption {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.permissionMetadata {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: fit-content;
}
.permissionDescription {
  font-style: italic;
  font-size: smaller;
}

.checkBoxContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;
  gap: 4px;
}

.singleCheckBoxContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.positiveButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.deleteRoleModal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  isolation: isolate;
  position: fixed;
  padding: 50px;
  width: 500px;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}

.deleteRoleModalPageTitle {
  font-size: 28px;
  margin-bottom: 24px;
  text-align: center;
}

.progressContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  padding: 0px;
  gap: 16px;
}

@media (max-height: 800px) {
  .formContainer {
    max-height: 70vh;
  }
}

@media (max-height: 650px) {
  .formContainer {
    max-height: 60vh;
  }
}
