.dashboardHeaderContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1rem;
  justify-content: space-between;
}
.dashboardHeaderContainer:first-child {
  margin-left: 1rem;
}
.inputsContainer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
.margin {
  margin-right: 16px;
}
.navButtonContainer {
  display: flex;
  flex-direction: row;
}
.navButton {
  all: unset;
  width: 20px;
  height: 50px;
  overflow: hidden;
  font-weight: 10;
}
.expandCharacter {
  padding: 0px 5px 6px 4px;
  display: inline-block;
  transform-origin: center;
  transform: scaleY(5);
  font-size: 20px;
  font-weight: inherit;
}
.navButton:hover {
  cursor: pointer;
  font-weight: 300;
}
