.authWrapper {
  width: 100%;
  height: 100%;
  gap: 20px;
  background-color: rgba(0, 0, 0, 0.2); /* Optional overlay background */
}
.authWrapperInner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  gap: 20px;
  background-color: rgba(0, 0, 0, 0.2); /* Optional overlay background */
}
.authWrapperRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.buttonContainer {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  padding: 20px;
  width: 344px;
  height: 185px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.ciscoDuoLogo {
  height: 40px;
  width: 40px;
}
.googleLogo {
  height: 50px;
  width: 50px;
}
.contentContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}
.federatedLoginButton {
  cursor: pointer;
  background: #ffffff;
  border-radius: 4px;
  border-width: thin;
  padding: 10px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.federatedLoginButton:hover {
  background: var(--hover-white-to-grey-color);
}
.trmiLogo {
  width: 70%;
  height: 70%;
}
.tireRackDotComLogo {
  width: 304px;
  height: 63px;
}
