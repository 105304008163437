.inputsContainer {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2rem;
}

.toolsHolder {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}
