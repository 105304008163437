.containerWrapper {
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
}
.container {
  min-width: 23rem;
  width: fit-content;
  border: thin solid #c2c2c2;
  border-radius: 8px;
  padding: 20px;
}
.containerHeading {
  font-weight: 700;
  font-size: 21px;
  margin-bottom: 28px;
}
.reportUploadsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.link {
  cursor: pointer;
  margin-right: 24px;
  color: #d70000;
  margin-top: auto;
  margin-bottom: auto;
}
.navLink {
  cursor: pointer;
  all: unset;
}
.navLink:hover {
  text-decoration: underline;
  cursor: pointer;
}
.navLink:visited {
  color: #d70000;
}
.uploadButton {
  cursor: pointer;
  color: #d70000;
}
.biReportModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  isolation: isolate;
  position: fixed;
  padding: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}
