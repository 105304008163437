@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

html {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
}

.font--x-large {
    font-size: 21px
}

.font--large {
    font-size: 17.5px
}

.font--light {
    font-weight: 300;
}

.font--semi-bold {
    font-weight: 600;
}

.font--bold {
    font-weight: 700;
}

.font--action-text {
    color: #D70000;
    line-height: 1;
}

.font--action-text:hover {
    cursor: pointer;
    text-decoration: underline;
}