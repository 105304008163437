.smallPartSuppliesModal {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 40px;
	isolation: isolate;
	position: fixed;
	padding: 50px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #ffffff;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
	border-radius: 12px;
	gap: 16px;
}
.smallPartSuppliesModalPageTitle {
	font-size: 28px;
}
.buttonContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: flex-start;
	width: 100%;
	padding: 0px;
	gap: 8px;
	margin-top: 24px;
}
.checkBoxContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.smallPartSuppliesFormContainer {
	width: 100%;
	display: flex;
	gap: 8px;
}
.inputsContainer {
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;
}
.progressContainer {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.errorMessage {
	color: #d70000;
}
.btn {
	all: unset;
	cursor: pointer;
	color: #d70000;
	padding-left: 5px;
	height: 30px;
}
.uploadButton {
  cursor: pointer;
  color: #d70000;
	width: fit-content;
}
.uploadButton:hover {
  text-decoration: underline;
}
.qrCodeContainer {
	width: 300;
	height: 300;
	border-radius: 8px;
  margin-bottom: 16px;
}
