.modalContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  padding: 1rem;
  background-color: #f5f5f5;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: relative;
  border-radius: 1rem;
}

.modalHeader {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.customerName {
  margin-bottom: 2rem;
  font-weight: 600;
}

.genericTireInput {
  margin: 0 0 1.5rem !important;
}

.modalFooter {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
}