.tipsPage {
	display: flex;
	justify-content: center;
	align-items: center;
	isolation: isolate;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #f5f5f5;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
	overflow-y: auto;
	padding-top: 2rem;
	padding-bottom: 2rem;
}
.header {
	font-size: 35px;
	font-weight: 700;
}
.progressContainer {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.tipsContainer {
	display: flex;
	flex-direction: column;
	border: 1px solid #c2c2c2;
	border-radius: 12px;
	max-width: 650px;
	height: fit-content;
	padding: 40px;
	gap: 32px;
	background-color: #ffffff;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}
.text {
	margin-top: 0%;
	font-size: 17.5px;
}
.logo {
	width: 142px;
	height: 32px;
}
.complianceAndButtonContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.complianceLinks {
	margin-left: auto;
	margin-right: auto;
}
.tipAmountContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 16px;
}
.defaultOptions {
	display: flex;
	width: 100%;
	gap: 16px;
}
.option {
	border: 1px solid #c2c2c2;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-grow: 1;
	flex-shrink: 1;
	padding: 24px 40px;
	cursor: pointer;
}
.option:hover {
	background-color: var(--color-warning);
}
.selected {
	background-color: var(--color-warning);
}
.percentageAmount {
	font-weight: 700;
	font-size: 35px;
}
.tipAmount {
	font-size: 17.5px;
}
.customTipContainer {
	border: 1px solid #c2c2c2;
	border-radius: 8px;
	flex-grow: 1;
	flex-shrink: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 24px;
	gap: 16px;
	cursor: pointer;
}
.textField {
	max-width: 300px;
	background-color: #ffffff;
}
.customTipContent {
	background-color: none;
	/* margin-top: 8px; */
}
@media (max-width: 600px) {
	.header {
		font-size: 28px;
		text-align: center;
	}

	.tipsContainer {
		padding: 20px;
		max-width: 350px;
		max-height: 700px;
	}
	.defaultOptions {
		gap: 8px;
	}
	.option {
		padding: 16px 20px;
	}

	.percentageAmount {
		font-size: 24px;
	}

	.tipAmount {
		font-size: 14px;
	}

	.customTipContainer {
		padding: 16px;
	}
	.tipsContainer {
		gap: 16px;
	}
}
