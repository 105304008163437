.virImageModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  isolation: isolate;
  position: fixed;
  padding: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}
.pageTitle {
  font-size: 28px;
  margin-bottom: 32px;
}
.virImagesMainContainer {
  display: flex;
  flex-direction: column;
  max-height: 800px;
  gap: 8px;
}
.imageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.imageLabel {
  font-size: 13px;
}
.imageLinks {
  all: unset;
  color: #d70000;
  cursor: pointer;
}
.imagesFlexContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 24px;
  overflow-y: auto;
  max-height: 600px;
}
.imageTitle {
  font-weight: 600;
  font-size: 16px;
}
.virImage {
  width: 300px;
  height: 533px;
  object-fit: cover;
}
