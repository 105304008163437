.paginationControls {
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
  padding: 0 1rem;
}
.buttonsContainer {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.tfooter {
  position: sticky;
  bottom: 0;
  background-color: #fff;
  z-index: 1;
}
