.makePriceChangeModal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;
  isolation: isolate;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}
.buttonContainer {
  display: flex;
  column-gap: 1rem;
  justify-content: space-between;
  width: 100%;
  margin-top: 2rem;
}
.formContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
}
.priceChangeModalPageTitle {
  font-size: 28px;
  margin-bottom: 24px;
  text-align: center;
}
