.primaryButton {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px 20px;
	border: none;
	background: #d70000;
	border-radius: 100px;
	min-width: 120px;
	text-decoration: none;
	color: inherit;
	height: 40px;
}

.primaryButton:hover {
	background: #a30000;
	cursor: pointer;
}

.primaryButton:visited {
	color: inherit;
}

.primaryButtonText {
	font-family: "Poppins";
	font-style: normal;
	gap: 4px;
	font-weight: 600;
	font-size: 13px;
	/* line-height: 150%; */
	display: flex;
	justify-content: center;
	align-items: center;
	color: #ffffff;
	flex-grow: 0;
	text-align: center;
}
