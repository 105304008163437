.contentContainer {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}
.tableContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
