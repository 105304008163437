.calendarContainer {
  width: 1000px;
  /* height: 368px; */
}
.prevNextContainer {
  display: flex;
  display: row;
  gap: 2rem;
  justify-content: center;
}
.blueLinkButton {
  color: blue;
}
.blueLinkButton:hover {
  text-decoration: underline;
  cursor: pointer;
}
.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
  color: grey;
}
.dateText {
  display: flex;
  display: row;
  gap: 1em;
}
