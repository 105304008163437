.downloadReportsModal {
  display: flex;
  flex-direction: column;
  padding: 40px;
  isolation: isolate;
  position: fixed;
  width: 1000px;
  max-height: 900px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  overflow-y: auto;
}
.reportTitle {
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 2%;
}
.reportsLinkContainer {
  width: 100%;
  border: thin solid #c2c2c2;
  border-radius: 8px;
  padding: 15px 20px;
}

.buttonContainer {
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 15px;
}
.reportContainer {
  width: 100%;
  border-bottom: thin solid #c2c2c2;
  color: #d70000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.inputsContainer {
  width: 100%;
  border-bottom: thin solid #c2c2c2;
  color: #d70000;
  display: flex;
  gap: 4px;
}
.navLink {
  cursor: pointer;
  all: unset;
}
.navLink:hover {
  text-decoration: underline;
  cursor: pointer;
}
.navLink:visited {
  color: #d70000;
}
div.reportsLinkContainer div.buttonContainer:last-child {
  border: none;
}
.inputsLabel {
  font-weight: 700;
  font-size: 17;
  color: black;
  display: inline-block;
  margin-left: 24px;
}
.primaryButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  border: none;
  background: #d70000;
  border-radius: 100px;
  min-width: 120px;
  text-decoration: none;
  color: inherit;
  height: 40px;
}

.primaryButton:hover {
  background: #a30000;
  cursor: pointer;
}

.primaryButton:visited {
  color: inherit;
}

.primaryButtonText {
  font-family: 'Poppins';
  font-style: normal;
  gap: 4px;
  font-weight: 600;
  font-size: 13px;
  /* line-height: 150%; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  flex-grow: 0;
  text-align: center;
}

.descriptionText {
  font-size: 12px;
  font-style: italic;
  color: #555;
}
