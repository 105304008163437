.MuiCheckbox-root {
  color: #d70000;
}

.Mui-checked {
  color: #d70000;
}

.checkBoxWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
}

.enabledTextColor {
  color: #141414;
}

.disabledTextColor {
  color: #808080;
}

.label {
  cursor: pointer;
}
