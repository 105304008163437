:root {
  --border-color: #555555;
  --grey-background-color: #d9d9d9;
  --white-background-color: #fff;
}

/* Ensure the table container allows vertical and horizontal scrolling */
.tableWrapperContainer {
  max-height: 75vh;
  border: 1px solid var(--border-color);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-radius: 8px;
  width: 100%;
  margin-top: 1rem;
  font-size: 1rem;
}

/* Basic table styling */
.futurePercentBookedTable {
  width: 100%;
  border-spacing: 0;
}

.headerCell {
  padding: 16px 8px;
  border-bottom: 1px solid var(--border-color);
  background-color: #b7b7b7;
  position: sticky;
  top: 0;
  width: 5.5rem;
  white-space: nowrap;
}

.headerCellWithToolTip {
  padding: 16px 8px;
  border-bottom: 1px solid var(--border-color);
  background-color: #b7b7b7;
  position: relative;
  top: 0;
  width: 5.5rem;
  white-space: nowrap;
}

.headerCellWithToolTip::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 120%;
  left: 50%;
  transform: translateX(-50%);
  width: 250px; /* Set fixed width */
  background-color: rgba(0, 0, 0, 0.75);
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 1rem;
  opacity: 0;
  visibility: hidden;
  white-space: normal; /* Allow text to wrap */
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 10;
  text-align: left; /* Center-align text if desired */
  box-sizing: border-box; /* Ensure padding doesn’t expand width */
}

.headerCellWithToolTip:hover::after {
  opacity: 1;
  visibility: visible;
}

.headerCell:nth-child(1),
.headerCell:nth-child(2) {
  z-index: 2000;
}
.headerCell:nth-child(n + 3) {
  z-index: 1000;
}

.futurePercentBookedTable td,
.futurePercentBookedTable th {
  padding: 12px 4px;
  text-align: left;
  border: 1px solid var(--border-color);
}

.futurePercentBookedTable td {
  padding: 12px 8px;
}

/* Alternate between grey and white every other row */
.futurePercentBookedTable tr:nth-child(2n + 1) td:nth-child(1),
.futurePercentBookedTable tr:nth-child(2n + 1) td:nth-child(2) {
  background-color: var(--grey-background-color);
}
.futurePercentBookedTable tr:nth-child(2n + 1) {
  background-color: var(--grey-background-color);
}

.futurePercentBookedTable tr:nth-child(2n + 2) td:nth-child(1),
.futurePercentBookedTable tr:nth-child(2n + 2) td:nth-child(2) {
  background-color: var(--white-background-color);
}
.futurePercentBookedTable tr:nth-child(2n + 2) {
  background-color: var(--white-background-color);
}

/* Remove left border on all but 1st cell for overlapping inside borders */
.futurePercentBookedTable td:not(:first-child),
.futurePercentBookedTable th:not(:first-child) {
  border-left: none;
}

/* Remove top border on all but 1st row for overlapping inside borders */
.futurePercentBookedTable tr:not(:first-child) td,
.futurePercentBookedTable tr:not(:first-child) th {
  border-top: none;
}

/* Remove overlapping outside borders for the first and last cells of each row */
.futurePercentBookedTable tr td:first-child,
.futurePercentBookedTable tr th:first-child {
  border-left: none;
}

.futurePercentBookedTable tr td:last-child,
.futurePercentBookedTable tr th:last-child {
  border-right: none;
}

/* for cells in the first and last row */
.futurePercentBookedTable tr:first-child td,
.futurePercentBookedTable tr:first-child th {
  border-top: none;
}

.futurePercentBookedTable tr:last-child td {
  border-bottom: none;
}

/* Ensure first header column is not sticky */
.futurePercentBookedTable th:nth-child(1),
.futurePercentBookedTable td:nth-child(1) {
  width: 10rem;
}

/* Subsequent columns scroll under the first two */
.futurePercentBookedTable td:nth-child(n + 3) {
  position: relative;
  z-index: 100;
}

/* Styling for special cells */
.totalCell {
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  border-top: none;
}

.highlightedCell {
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  border-bottom: none;
}
.highlightedCellWithTooltip {
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  border-bottom: none;
  cursor: help;
  color: red;
  font-weight: bold;
}
.highlightedCellWithTooltip::after {
  content: attr(data-tooltip);

  position: absolute;
  bottom: 0%;
  left: 0%;
  transform: translateX(20%);
  width: 500px; /* Set fixed width */
  background-color: rgba(0, 0, 0, 0.75);
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 1rem;
  opacity: 0;
  visibility: hidden;
  white-space: normal; /* Allow text to wrap */
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 10000;
  text-align: left; /* Center-align text if desired */
  box-sizing: border-box; /* Ensure padding doesn’t expand width */
}
.highlightedCellWithTooltip:hover::after {
  opacity: 1;
  visibility: visible;
}

.noVerticalBorders {
  border-top: none;
  border-bottom: none;
  background-color: #f8f8f8;
}

.weeklyTotalCell,
.highlightedCell,
.totalCell {
  font-weight: bold;
}

.totalCell {
  background-color: #f8f8f8;
}

/* Remove left border for first cell in rows */
.futurePercentBookedTable tr td:first-child,
.futurePercentBookedTable tr th:first-child {
  border-left: none;
}
.columnDefinitionHeader {
  min-width: fit-content;
  font-weight: bold;
}
.columnDefinitionContainer {
  display: flex;
  flex-direction: column;
  max-width: 50%;
  min-width: 200px;
}
.columnDefinitionEntry {
  display: flex;
  flex-direction: row;
}
.additionalTextHeader {
  min-width: fit-content;
  font-weight: bold;
}
.additionalTextContainer {
  display: flex;
  flex-direction: column;
  max-width: 75%;
  min-width: 500px;
}
.additionalTextEntry {
  display: flex;
  flex-direction: row;
}
