* {
	box-sizing: border-box;
}

html {
	height: 100%;
	background-color: #eeeeee;
	font-family: 'Poppins', sans-serif;
}

body {
	margin: 0;
	height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
	background-color: black;
	color: white;
	border-radius: 1rem;
	padding: 1rem;
	font-weight: bold;
}

#root {
	height: 100%;
}

table[role='table'] .-filters {
	background-color: #eee;
	border: none;
	padding: 0.5rem 1.5rem 0.5rem 0.5rem;
	border-radius: 0.25rem;
}
/* table[role=table] input,
.-filters {
    background-color: #EEE;
    border: none;
    padding: .5rem 1.5rem .5rem .5rem;
    border-radius: .25rem;
} */

th[role='columnheader'] > div:first-child {
	text-align: left;
	margin: 0 0 0.5rem 0.5rem;
}
.MuiCircularProgress-colorPrimary {
	color: #d70000 !important;
}

.warning-message {
  background-color: var(--color-warning);
	padding: .5rem;
	font-size: 12px;
	font-weight: bold;
	border-radius: 4px;
	text-align: center;
}