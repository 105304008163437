.scheduleModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  isolation: isolate;
  position: fixed;
  padding: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}
.scheduleModalPageTitle {
  font-size: 28px;
  margin-bottom: 32px;
}
.inputsContainer {
  display: flex;
  flex-direction: column;
}
.trackingLinksContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 0px;
  gap: 8px;
  margin-top: 16px;
}
