.formContainer {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 0px;
	gap: 40px;
}
.responsiveFormContainer {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	padding: 2rem;
	background: white;
	gap: 20px;
}
.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.3);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
}

.overlay .progressContainer {
	text-align: center;
}

.addressConfirmBox {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 2rem;
	background: white;
	gap: 20px;
	left: 50%;
	top: 50%;
	position: fixed;
	transform: translate(-50%, -50%);
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
	border-radius: 1rem;
	width: 100%;
	max-width: 400px;
}

@media (max-width: 1023px) {
	.responsiveFormContainer {
		flex-direction: column;
		align-items: center;
	}
}
@media (max-width: 660px) {
	.responsiveFormContainer {
		padding: 0 0.5rem;
	}
}
