.mainPaymentInformationContainer {
	display: flex;
	flex-direction: row;
	padding: 2rem 2rem 2rem 2rem;
	gap: 40px;
	width: 100%;
	height: 100%;
	background: white;
	justify-content: space-between;
}

.noChargeAndPaymentInfoContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0px;
	gap: 40px;
	max-width: 650px;
}

.noChargeContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;
	gap: 32px;
	width: 680px;
	margin-top: 8px;
}

.switchContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px 1px;
	gap: 12px;
	width: 165px;
	height: 24px;
}

.noChargeLabel {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 150%;
	display: flex;
	align-items: center;
	color: #141414;
}

.ldsRing {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}

.ldsRing div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 64px;
	height: 64px;
	margin: 8px;
	border: 8px solid #0079d9;
	border-radius: 50%;
	animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #0079d9 transparent transparent transparent;
}

.ldsRing div:nth-child(1) {
	animation-delay: -0.45s;
}

.ldsRing div:nth-child(2) {
	animation-delay: -0.3s;
}

.ldsRing div:nth-child(3) {
	animation-delay: -0.15s;
}

@keyframes ldsRing {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.3);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
}

.overlay .progressContainer {
	text-align: center;
}

@media (max-width: 1023px) {
	.noChargeAndPaymentInfoContainer {
		width: 60%;
	}
	.mainPaymentInformationContainer {
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
}
@media (max-width: 650px) {
	.noChargeAndPaymentInfoContainer {
		width: 100%;
	}
	.mainPaymentInformationContainer {
		padding: 0 1rem;
		justify-content: flex-start;
	}
}
