.mainContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
}
.flexEnd {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
}
.flexStart {
  display: flex;
  align-items: center;
}
.btnsContainer {
  display: flex;
  align-items: center;
}
.btn {
  all: unset;
  cursor: pointer;
  color: #d70000;
  padding-right: 20px;
  padding-left: 20px;
  height: 30px;
}
.btn:hover {
  text-decoration: underline;
}
.btnsContainer > button:nth-child(1) {
  border-right: thin solid #c2c2c2;
}

.deleteTechModal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  isolation: isolate;
  position: fixed;
  padding: 50px;
  width: 400px;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}

.deleteTechModalPageTitle {
  font-size: 28px;
  margin-bottom: 24px;
  text-align: center;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 0px;
  gap: 16px;
}
.errorMessage {
  color: red;
  margin-bottom: 8px;
}
.text {
  margin-bottom: 16px;
}
.progressContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
