.confirmAddressContainer {
  display: flex;
  margin: 2rem 0 1rem;
  flex-direction: column;
  gap: 1rem;
  border: thin solid #e0e0e0;
  border-left: none;
  border-right: none;
  padding: 1rem 0;
}

.prompt {
  font-size: 16px;
  margin-top: 1rem;
}

.buttonsContainer {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  justify-content: flex-end;
}

.confirm {
  font-size: 16px;
  font-weight: bold;
  padding: 0 0 1rem;
}

@media screen and (max-width: 768px) {

  .buttonsContainer {
    display: flex;
    flex-direction: column;
  }
}