.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.heading {
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 16px;
}
.saveButton {
  all: unset;
  cursor: pointer;
  color: #d70000;
}
.saveButton:disabled {
  color: #c2c2c2;
  text-decoration: none;
  cursor: not-allowed;
}
