.secondaryButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    gap: 10px;
    border: 1px solid #D70000;
    border-radius: 100px;
    width: 120px;
    height: 45px;
    background: #FFFFFF;
    color: #D70000;
}

.secondaryButton:hover span {
    color: #FFFFFF;
    cursor: pointer;
}

.secondaryButton:hover {
    background: #D70000;
    cursor: pointer;
}

.secondaryButton:disabled {
    border: 1px solid #C2C2C2;
    border-radius: 100px;
	background: #efefef;
    color: #767676;
    pointer-events: none;
}

.secondaryButton:disabled span {
    color: #767676;
}

.secondaryButtonText {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
}