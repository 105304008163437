.routeBuilderContainer {
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 100%;
	margin-top: 24px;
	margin-bottom: 24px;
}
.title {
	font-weight: 700;
	font-size: 24px;
}
.inputs {
	display: flex;
	gap: 8px;
}
.note {
	max-width: 800px;
}
