html,
body {
  overflow: hidden;
  position: fixed;
}

.baseContainerWrapper {
  margin: 0;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: max-content auto;
  overflow: hidden;
  position: fixed;
}

/* .routes {
  height
} */