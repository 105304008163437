.primaryButton {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 12px 24px;
	gap: 10px;
	background: #d70000;
	border: none;
	border-radius: 100px;
	color: #ffffff;
	min-width: 120px;
	max-height: 45px;
}

.primaryButton:hover {
	background: #a30000;
	cursor: pointer;
}

.primaryButton:disabled {
	border: 1px solid #c2c2c2;
	background: #efefef;
	color: #767676;
  pointer-events: none;
}

.primaryButtonText {
	height: 21px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 150%;
	display: flex;
	justify-content: center;
	flex: none;
	order: 0;
	flex-grow: 0;
}