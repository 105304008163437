.bodyRowCell {
  padding: .5rem;
}

.textfieldBackground {
  color: #ffffff;
}

.titleButtonContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: baseline;
}

.buttonCell {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
}

.buttonText {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #d70000;
  cursor: pointer;
  border-right: 3px solid #c2c2c2;
  padding: 0 .5rem;
}
.buttonText:hover {
  text-decoration: underline;
}
.buttonText:first-child {
  padding-left: 0;
}
.buttonText:last-child {
  border-right: none;
}

.line {
  width: 24px;
  height: 0px;
  border: 1px solid #c2c2c2;
  transform: rotate(90deg);
}
td.bodyRowCell:first-child {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.daySettingActivityLogModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  isolation: isolate;
  position: fixed;
  padding: 24px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}

.tableContainer {
  margin-bottom: 2rem;
}

.deleteConfirmationModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  isolation: isolate;
  position: fixed;
  padding: 24px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}
.deleteConfirmMessage {
  max-width: 250px;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
}
.deleteButtonContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}
.error {
  color: #d70000;
}