.scheduleModal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  gap: 32px;
  isolation: isolate;
  position: absolute;
  max-width: 1120px;
  width: calc(100% - 10rem);
  max-height: calc(100% - 10rem);
  left: 50%;
  transform: translateX(-50%);
  top: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}
.flexGroupButtons {
  display: flex;
  gap: 8px;
}
.scheduleModalPageTitle {
  font-size: 28px;
  height: 42px;
  width: 100%;
  display: flex;
  gap: 40px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 0px;
  gap: 16px;
}
