.casesModal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  gap: 16px;
  isolation: isolate;
  position: absolute;
  min-width: 700px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}

.casesModalHeader {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 0px;
  gap: 10px;
}

.casesModalFooterButtons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.casesModalPageTitle {
  font-size: 28px;
}

.mainContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  height: 100%;
}

.inputsContainer {
  display: flex;
  flex-direction: column;
}

.topInputsContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  width: 100%;
}
.topInputsContainer > div {
  margin-bottom: 16px;
}
.notesTitle {
  font-weight: 700;
  font-size: 17.5px;
}
.notesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 90%;
  border: thin solid #c2c2c2;
  border-radius: 5px;
  padding: 10px;
}
.notesBox {
  width: 100%;
  height: 250px;
  overflow-y: scroll;
}
.note {
  margin-bottom: 8px;
}
.addNoteButton {
  cursor: pointer;
}

.progressContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
