.navDrawerContainer {
  padding: 1.5rem 0 0;
}

.logoHolder {
  margin-bottom: 1.5rem;
}

.logo {
  width: 150px;
}

.header {
  padding: 0 1.5rem;
}

.headerInfo {
  margin-bottom: 1.5rem;
}

.link {
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  box-sizing: border-box;
  border-left: 3px solid transparent;
  display: block;
  text-decoration: none;
  text-decoration-color: inherit;
}

.link:link {
  color: inherit;
}

.link:visited {
  color: inherit;
}

.link.chosen {
  border-left: 3px solid red;
}
.userContainer {
  padding: 3rem 1.5rem 1.5rem 1.5rem;
  box-sizing: border-box;
  display: block;
}
.userName {
  font-style: normal;
  font-weight: bold;
  font-size: 12pt;
}
.userInfo {
  font-style: normal;
  font-weight: normal;
  font-size: 10pt;
}
