.alert {
	width: 30vw;
	font-size: 14px;
}
@media (max-width: 660px) {
	.alert {
		width: 85%;
		font-size: 14px;
	}
}
