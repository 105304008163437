.tableContainer {
  position: relative;
  width: auto;
  max-height: 85vh;
  border: 1px solid #c2c2c2;
  border-radius: 8px;
  overflow-y: auto;
  overflow-x: auto;
}

.progressContainer {
  min-height: 200px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
}

/* .tableContainer::-webkit-scrollbar {
  display: block;
}
.tableContainer::-webkit-scrollbar {
  width: 10px;
}
.tableContainer::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.tableContainer::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 4px;
} */
