.lineItemAllocatorMain {
  margin-bottom: 2rem;
  width: 100%;
  max-width: 40rem;
}

.lineItemAllocatorContainer {
  width: 100%;
  padding: 1rem;
  background-color: #eee;
  border-radius: 0.5rem;
}

.helperText {
  font-style: italic;
}

.additionalActions {
  border-bottom: thin solid #c2c2c2;
  margin-bottom: 1rem;
}

.lineItemRow {
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-gap: 1rem;
  margin-bottom: 0.5rem;
  align-items: center;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  width: max-content;
}