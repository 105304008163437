.formContainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.errorContainer {
  height: 40px;
  padding: none;
}
.error {
  margin: 0;
}
.left {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 240px;
}
.middle {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 300px;
}
.right {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 300px;
}
