.updateCcPage {
	display: flex;
	justify-content: center;
	isolation: isolate;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #f0ecec;
	overflow: auto;
	padding-top: 2rem;
	padding-bottom: 2rem;
}
.header {
	font-size: 35px;
	font-weight: 700;
}
.progressContainer {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.orderInfo {
	margin-top: 5px;
	margin-bottom: 10px;
	display: flex;
	flex-direction: column;
	gap: 2px;
}
.updateCardContainer {
	margin: auto;
	display: flex;
	flex-direction: column;
	border: 1px solid #c2c2c2;
	border-radius: 12px;
	max-width: 700px;
	padding: 30px;
	gap: 32px;
	background-color: #ffffff;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}
.text {
	font-size: 17.5px;
}
.logo {
	width: 142px;
	height: 32px;
}
.statusMessages {
	margin-top: 20px;
	text-align: center;
}
.complianceAndButtonContainer {
	margin-top: 20px;
	width: 100%;
	display: flex;
	flex-direction: column;
}
.buttonContainer {
	width: 100%;
	display: flex;
	justify-content: center;
}
@media (max-width: 600px) {
	.updateCardContainer {
		max-width: 320px;
	}
	.header {
		font-size: 16px;
		text-align: center;
	}
}