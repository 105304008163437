.rowContainer {
	border-bottom: 1px solid #c2c2c2;
	display: flex;
	width: 2086px;
}

.blank {
	background-color: #f9f9f9;
}

.techInfo {
	width: 240px;
}

.timeSlot {
	width: 609px;
	display: flex;
	border-left: 1px solid #c2c2c2;
	overflow: auto;
	align-items: center;
	padding-left: 8px;
	padding-right: 8px;
}