.workOrderDetailModal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;
  isolation: isolate;
  position: absolute;
  max-width: 1500px;
  width: calc(100% - 10rem);
  height: 880px;
  left: 50%;
  transform: translateX(-50%);
  top: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}
.pageTitle {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 24px;
}
.woDetailsContent {
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
}

.scheduleModalPageTitle {
  font-size: 28px;
}
.tablesWrapper {
  width: 80%;
  height: 100%;
  overflow-y: scroll;
  margin-right: 30px;
}
.sideFlexWrapper {
  display: flex;
  flex-direction: column;
  width: 20%;
  overflow-y: scroll;
}
.tableContainer {
  width: 100%;
  height: 300px;
  border: thin solid #c2c2c2;
  border-radius: 8px;
  margin-bottom: 30px;
}
.sideNotesContainer {
  width: 100%;
  margin-bottom: 24px;
}
.tablesWrapper > div {
  margin-bottom: 20px;
}
.progressContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
