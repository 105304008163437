.dropDownSelect {
    width: 240px;
}

.dropDownSelect > label {
    font-family: 'Poppins', sans-serif;
}

.dropDownOption {
    font-family: 'Poppins', sans-serif;
}