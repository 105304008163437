.tableHeadersContainer {
  width: 2086px;
  height: 45px;
  display: flex;
  border-bottom: 1px solid #c2c2c2;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #fff;
}
.tech {
  width: 240px;
  height: 100%;
  display: flex;
  align-items: center;
}
.timeSlot {
  width: 609px;
  height: 100%;
  display: flex;
  align-items: center;
  border-left: 1px solid #c2c2c2;
}
.headerText {
  margin-left: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #141414;
}
