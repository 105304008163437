
.buttonContainer {
	width: 100%;
	display: flex;
	direction: row;
	gap: 8px;
	justify-content: center;
}
.contentContainer {
	display: flex;
	flex-direction: column;
	max-width: 90%;
	max-height: 100%;
	gap: 16px;
	background-color: #ffffff;
	overflow: hidden;
}
.customerName {
	font-family: Poppins;
	font-size: 24px;
	font-weight: 400;
	line-height: 36px;
	letter-spacing: 0px;
}
.defaultOptions {
	display: flex;
	width: 100%;
	gap: 16px;
}
.discount {
	margin-left: 4px;
}
.discounts {
	margin-bottom: 4px;
}
footer {
	width: 100%;
	margin-top: auto;
	display: flex;
	flex-direction: column;
	gap: 8px;
	height: 90px;
	align-items: center;
	justify-content: center;
	background-color: #ffffff;
	position: fixed;
	bottom: 0;
	left: 0;
}
.footerButtons {
	display: flex;
	flex-direction: row;
	gap: 8px;
}
.footerLinks {
	display: flex;
	flex-direction: row;
	gap: 16px;
}
.footerLinks a {
	color: black;
}
.header {
	font-family: Poppins;
	font-size: 18px;
	font-weight: 700;
	line-height: 27px;
	letter-spacing: 0px;
	text-align: left;
	margin-bottom: 4px;
}
.headerContainer {
	justify-content: left;
	width: 90%;
}
.informationContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 19px 16px 19px 16px;
	max-width: 400px;
	border-radius: 10px;
	border: 0.5px;
	gap: 16px;
	border: 0.5px solid #C2C2C2;
	background: #F9F8F8;
	overflow: auto;
	max-height: calc(100% - 35px);
}
.installationAppointment {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 600;
	line-height: 21px;
	letter-spacing: 0px;
}
.inputsContainer {
  display: flex;
  flex-direction: column;
}
.logo {
	width: 142px;
	height: 32px;
}
.logoContainer {
	display: flex;
	justify-content: center;
	padding-top: 20px;
}
.orderShippedMessage {
	display: flex;
	flex-direction: row;
	gap: 4px;
}
.orderInformationContainer {
	width: 100%;
	max-height: 100%;
	padding: 16px;
	border-radius: 8px;
	background-color: #ffffff;
}
.orderTrackerContent {
	align-items: left;
	max-height: 100%;
	flex-grow: 1;
}
.orderTrackerContentContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-height: calc(100dvh - 14rem);
	flex-grow: 1;
	overflow: hidden;
}
.orderTrackerWrapper {
	display: flex;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	background-color: #ffffff;
	overflow: hidden;
}
.percentageAmount {
	font-weight: 700;
	font-size: 35px;
}
.progressContainer {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.technicianPicture {
	height: 40%;
	width: 40%;
	border: 2px solid #C2C2C2;
	border-radius: 8px;
}
.technicianPictureContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;
	width: 100%;
	height: fit-content;
	padding: 16px;
	border: 1px solid #C2C2C2;
	border-radius: 8px;
	background-color: #ffffff;
}
.tireRackOrderContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 4px;
	height: fit-content;
	padding: 16px;
	border: 1px solid #C2C2C2;
	border-radius: 8px;
	background-color: #ffffff;
	width: 100%;
}
.tireRackOrderLeftContainer {
	display: flex;
	flex-direction: column;
	align-items: left;
	gap: 4px;
	height: fit-content;
}
.tireRackOrderNumberInput {
	width: 50%;
}
.tireRackOrderNumberInputContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 16px;
	padding-top: 8px;
}
.tireRackOrderHeader {
	font-family: Poppins;
	font-size: 18px;
	font-weight: 400;
	line-height: 27px;
	letter-spacing: 0px;
	text-align: left;
}
.trackingInformationContainer {
	display: flex;
	flex-direction: column;
	align-items: left;
	gap: 16px;
	width: 100%;
	height: fit-content;
	padding: 16px;
	border: 1px solid #C2C2C2;
	border-radius: 8px;
	background-color: #ffffff;
}
.rescheduleLink {
	color: #2660F5;
	cursor: pointer;
	text-decoration: underline;
}
.selected {
	background-color: var(--color-warning);
}
.serviceSummaryContainer {
	width: 100%;
	height: fit-content;
	padding: 16px;
	border-radius: 8px;
	background-color: #ffffff;
}
.serviceSummaryHeader {
	font-family: Poppins;
	font-size: 18px;
	font-weight: 400;
	line-height: 27px;
	letter-spacing: 0px;
	text-align: left;
}
.textField {
	max-width: 300px;
	background-color: #ffffff;
}



.subModalHeader {
	font-size: 28px;
	font-weight: bold;
	margin-bottom: 16px;
}

/* Confirm Cancel Modal */
.cancelMessageContainer {
	text-align: center;
  margin-bottom: 16px;
}
.checkBoxContainer {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
}
.subModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  isolation: isolate;
  position: fixed;
  padding: 50px;
  top: 50%;
  left: 50%;
	min-width: 335px;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}
.inputsContainer {
  display: flex;
  flex-direction: column;
}

/* Contact Us Modal */
.contactInfoContainer {
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;
	padding: 16px;
}

/* OrderTrackerBullet */
.inactiveMessage {
	color: #C2C2C2;
}
.bullet {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
}
.circle {
	height: 25px;
	width: 25px;
}
