.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.readOnly {
  justify-content: center;
  text-align: center;
  font-size: large;
  color: grey;
}
