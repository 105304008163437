.textfieldStyles {
	width: 320px;
}

.contactFormContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 16px;
}

.contactTitle {
	font-size: 21px;
}
@media (max-width: 600px) {
	.contactFormContainer {
		width: 100%;
	}
	.textfieldStyles {
		width: 100%;
	}
}
