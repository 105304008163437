.scheduleModal {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 40px 20px;
	isolation: isolate;
	position: fixed;
	top: 30%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #ffffff;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
	border-radius: 12px;
	min-width: 50rem;
}
.inputsContainer {
	display: flex;
	width: 100%;
	margin-left: 40px;
	margin-right: 40px;
	align-items: center;
	justify-content: center;
}
.reportTitle {
	font-weight: 600;
	font-size: 24px;
	margin-bottom: 45px;
}
.buttonsContainer {
	display: flex;
	direction: row;
	justify-content: space-between;
	gap: 20px;
	margin-top: 60px;
	margin-left: 50px;
}
